<template>
  <div>
    <van-cell-group>
      <van-cell title="社区"
        :value="hzlist.OrgName" />
      <van-cell title="小区"
        :value="hzlist.Region" />
      <van-cell title="姓名"
        :value="hzlist.Name" />
      <van-cell title="身份证号"
        :value="hzlist.IDCard" />
      <van-cell title="联系方式"
        :value="hzlist.Mobile" />
      <van-cell title="出行时间"
        :value="hzlist.OutTime" />
      <van-cell title="交通工具"
        :value="hzlist.Vehicle" />
      <van-cell title="出行目的"
        :value="hzlist.City" />
      <van-cell title="重点停留地点"
        :value="hzlist.Address" />
      <van-cell title="返回时间"
        :value="hzlist.BackTime" />
      <van-cell title="返回交通工具"
        :value="hzlist.BackVehicle" />
      <van-cell title="是否有同行"
        v-if="hzlist.IsPeer == 1"
        value="是" />
      <van-cell title="是否有同行"
        v-else
        value="否" />
      <van-cell title="是否核酸检测"
        v-if="hzlist.IsCheck == 1"
        value="是" />
      <van-cell title="是否核酸检测"
        v-else
        value="否" />
      <van-cell title="居家隔离条件"
        v-if="hzlist.IsDivide == 1"
        value="有" />
      <van-cell title="居家隔离条件"
        v-else
        value="无" />
      <van-cell title="状态"
        :value="hzlist.StatusDesc" />
      <van-cell title="确认人"
        v-if="hzlist.Status == '2'"
        :value="hzlist.ConfirmMan" />
      <van-cell title="确认时间"
        v-if="hzlist.Status == '2'"
        :value="hzlist.ConfirmTime" />
      <van-cell title="确认结果"
        v-if="hzlist.Status == '2'"
        :value="hzlist.ConfirmResult" />
      <!-- <van-cell title="健康码"
        value="" /> -->
      <!-- <van-image width="100"
        height="100" 
        :src="HealthCodeUrl" /> -->
      <!-- <van-uploader v-model="HealthCodeUrl" /> -->

    </van-cell-group>
    <div style="width:95%;margin: 10px auto;">
      <van-button round
        block
        v-if="hzlist.Status == '1'"
        type="info"
        color="#617bfa"
        style="font-size: 16px"
        @click="SaveZh(hzlist.ID)">确认</van-button>
    </div>
    <van-dialog v-model="showTc"
      title="提示"
      @confirm='confirm'
      show-cancel-button>
      <van-cell-group inset>
        <van-field v-model="dataform.ConfirmResult"
          label="确认结果"
          placeholder="请输入确认结果" />
      </van-cell-group>
    </van-dialog>
    <!-- <van-dialog use-slot
      title="标题"
      v-model="showTc"
      show-cancel-button
      confirm-button-open-type="getUserInfo"
      bind:close="onClose"
      bind:getuserinfo="getUserInfo">
      <van-cell-group inset>
        <van-field v-model="dataform.ConfirmResult"
          label="确认结果" />
      </van-cell-group>
    </van-dialog> -->
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";

import { GetResident, GetReporting } from "@/api/wangge";
import { getwgToken } from "@/utils/auth";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      listfrom: {},
      hzlist: [],
      url: "https://lyxnyapi.dexian.ren/",
      HealthCodeUrl: [],
      showTc: false,
      dataform: {
        ConfirmResult: ''
      }
    }
  },
  created () {
    this.cyXq();
  },
  methods: {
    // 获取住户列表
    cyXq () {
      console.log(this.$route);
      // this.hzlist = JSON.parse(this.$route.params.Id)
      // 健康码{ url: 'https://cdn.jsdelivr.net/npm/@vant/assets/leaf.jpeg' },
      // this.HealthCodeUrl = this.url + this.hzlist.HealthCode
      console.log(this.HealthCodeUrl);
      this.listfrom.id = this.$route.params.Id;
      this.listfrom.accToken = getwgToken();
      GetReporting(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.hzlist = {};
            } else {
              this.hzlist = res.data.data;
            }
          }
        })
        .catch(() => { });
    },
    SaveZh: function (row) {
      // this.showTc = true
      console.log(row);
      this.$router.push({
        path: "/wangge/fangkong/fkFanQi/fqXiangQing/fanqiQueRen/" + row
      });
    },



    confirm: function () {
      if (this.dataform.ConfirmResult == '') {
        Toast.fail("请输入确认结果");
      } else {
        ConfirmReporting({ accToken: getwgToken(), ID: this.hzlist.ID, ConfirmResult: this.dataform.ConfirmResult })
          .then((res) => {
            console.log(res.data.code);
            if (res.data.code == 0) {
              Toast.success("操作成功");
              this.$router.go(-1)
            } else {
              Toast.fail(res.data.msg);
            }
          })
          .catch(() => { });
      }
      // this.showTc = true
    },
  },
}
</script>
<style scoped>
.van-cell {
  font-size: 15px !important;
}
.rightClass {
  width: 60% !important;
}
</style>